var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("gov-back-link", { attrs: { to: { name: "events-index" } } }, [
        _vm._v("Back to events")
      ]),
      _c(
        "gov-main-wrapper",
        [
          _vm.loading
            ? _c("ck-loader")
            : _c(
                "gov-grid-row",
                [
                  _c("vue-headful", {
                    attrs: {
                      title: _vm.appName + " - Event: " + _vm.event.title
                    }
                  }),
                  _c(
                    "gov-grid-column",
                    { attrs: { width: "full" } },
                    [
                      _c(
                        "gov-grid-row",
                        [
                          _c(
                            "gov-grid-column",
                            { attrs: { width: "two-thirds" } },
                            [
                              _c(
                                "gov-heading",
                                { attrs: { size: "m" } },
                                [
                                  _c("gov-caption", { attrs: { size: "m" } }, [
                                    _vm._v(_vm._s(_vm.event.title))
                                  ]),
                                  _vm._v(
                                    "\n              View event\n            "
                                  )
                                ],
                                1
                              ),
                              _vm.updated
                                ? _c("gov-inset-text", [
                                    _vm._v(
                                      "event " +
                                        _vm._s(_vm.event.title) +
                                        " has been updated"
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.auth.canEdit("event", _vm.event.organisation.id)
                            ? _c(
                                "gov-grid-column",
                                {
                                  staticClass: "text-right",
                                  attrs: { width: "one-third" }
                                },
                                [
                                  _c(
                                    "gov-button",
                                    {
                                      attrs: {
                                        to: {
                                          name: "events-edit",
                                          params: { event: _vm.event.id }
                                        }
                                      }
                                    },
                                    [_vm._v("Edit event")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("event-details", { attrs: { event: _vm.event } }),
                      _c(
                        "gov-button",
                        {
                          attrs: {
                            to: {
                              name: "events-duplicate",
                              params: { event: _vm.event.id }
                            },
                            success: true
                          }
                        },
                        [_vm._v("Duplicate event")]
                      ),
                      _vm.auth.canDelete("event")
                        ? [
                            _c("gov-section-break", { attrs: { size: "l" } }),
                            _c("gov-body", [
                              _vm._v(
                                "Please be certain of the action before deleting an\n            event"
                              )
                            ]),
                            _c("ck-delete-button", {
                              attrs: {
                                resource: "event",
                                endpoint:
                                  "/organisation-events/" + this.event.id
                              },
                              on: { deleted: _vm.onDelete }
                            })
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }